<template>
 <div></div>
</template>
<script >
import oidc from "@/libs/oidc.js";
import {
  saveLocal,
  
} from '@/libs/util'
export default {
  name: "callback",
  mounted() {
    setTimeout(() => {
       let mgr = oidc()
       mgr.signinRedirectCallback()
      .then( ()=> {
          mgr.getUser().then(user => {
          
                  if(user){
                    let accessToken = user.access_token
                    saveLocal('token', accessToken)
                    this.$store.commit('setToken', accessToken)
                    window.location = "/homeIndex";
                  }else{
                    mgr.signinRedirect()
                  }
            })
        
      })
      .catch(function (e) {
          console.log(e)
      });
    }, 2000)
  }
};
</script>